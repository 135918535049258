<template>
  <div>
    <zem-collapse v-for="(item, index) in elements" :key="'a' + index" :arrow="false" class="mt-0" not-border>
      <template v-slot:title>
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
            {{ item.type ? item.type.data.name : 'Тип не указан' }}
            <zem-dropdown-menu>
              <template v-slot:options>
                <RouterLink
                  target="_blank"
                  :to="`/events/${item.id}`"
                  class="tw-block tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E] tw-transition tw-text-xs"
                >
                  Перейти в карточку
                </RouterLink>
              </template>
            </zem-dropdown-menu>
          </div>
        </div>
      </template>
      <div class="zem-collapse-table rows">
        <div class="zem-collapse-table__row tw-grid-cols-[1fr_2fr] tw-gap-x-[10px] tw-gap-y-2.5">
          <div class="zem-collapse-table__column title">Дата начала</div>
          <div class="zem-collapse-table__column text">
            {{ item.start_at ? formattedDateTime(item.start_at) : 'Не указано' }}
          </div>
          <div class="zem-collapse-table__column title">Дата окончания</div>
          <div class="zem-collapse-table__column text">
            {{ item.finish_at ? formattedDateTime(item.finish_at) : 'Не указано' }}
          </div>
          <div class="zem-collapse-table__column title">Исполнитель</div>
          <div class="zem-collapse-table__column text">
            {{ item.responsible_by ? item.responsibleBy.data.name : '' }}
          </div>
        </div>
      </div>
    </zem-collapse>
    <RouterLink
      :to="`/events?order_id=${order.id}&order_title=${order.title}`"
      class="tw-flex tw-items-center tw-text-xs tw-mt-2 text-primary tw-font-semibold"
    >
      <PlusIcon class="tw-mr-1 tw-font-bold" size="14" stroke-width="3" />
      Мероприятие
    </RouterLink>
  </div>
</template>

<script>
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import {formattedDateTime, logger} from '@/assets/scripts/scripts'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import {ExternalLinkIcon, PlusIcon} from 'vue-feather-icons'
import ZemDropdownMenu from '@/components/ui/ZemDropdownMenu.vue'

export default {
  components: {
    ZemDropdownMenu,
    ZemCollapse,
    ZemRadioButton,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ExternalLinkIcon,
    PlusIcon,
  },
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isCreating: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: null,
    },
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {}
  },
  methods: {
    logger,
    formattedDateTime,
    values(item) {
      if (item.type === 'list') {
        return item.data.title
      } else if (item.type === 'groups') {
        let valuesToString = ''
        item.data.forEach((el, index, arr) => {
          valuesToString += el.title
          if (arr.length !== index + 1) {
            valuesToString += ', '
          }
        })
        return valuesToString
      } else if (item['input_name'] === 'bundle') {
        return item.data.title
      } else {
        return item.data
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-dropdown {
  margin-top: 0;
}
</style>
