<template>
  <div class="zem-collapse-table rows">
    <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
      <template v-for="(participant, index) in elements">
        <div :key="index" class="zem-collapse-table__column title mt-1">{{ participant.human_position }}</div>
        <RouterLink
          :to="`/counterparties/peoples/${participant.id}`"
          class="zem-collapse-table__column tw-text-xs tw-text-[#0DB2B2] hover:tw-underline tw-cursor-pointer tw-leading-[1] tw-font-semibold"
          target="_blank"
        >
          <span v-tippy="{content: 'Перейти в контрагента'}">{{ participant.human }}</span>
        </RouterLink>
      </template>
    </div>
  </div>
</template>

<script>
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'

export default {
  components: {
    ZemCollapse,
    ZemRadioButton,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
  },
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped></style>
