<template>
  <!-- Перевод добавлен -->
  <div class="custom-tree-container">
    <div class="block">
      <zem-input v-model="filterText" :placeholder="$t('Filter')" class="mb-1"></zem-input>
      <el-tree
        ref="tree"
        :data="list"
        :draggable="draggable"
        :empty-text="$t('No categories found')"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        :show-checkbox="checkboxes"
        class="mt-2"
        node-key="id"
        @node-drag-end="changeTree"
      >
        <span
          slot-scope="{node, data}"
          class="custom-tree-node"
          @contextmenu.prevent="openContextMenu($event, {data: data, node: node})"
          @click.self="handleNodeClick(data, node)"
        >
          <span v-if="!data.edit" class="tw-flex tw-items-center tw-relative" @click.self="handleNodeClick(data, node)">
            <span
              v-if="node.level === 2"
              :style="{'background-color': data.params.color}"
              class="tw-block tw-rounded-full tw-h-[6px] tw-w-[6px] tw-mr-2 tw-absolute tw-left-[-12px]"
            ></span>
            {{ data.name }}{{ node.level === 2 && !$store.state.orders.isArchive ? ` (${data['total_orders']})` : '' }}
          </span>
          <span v-else class="rename-block" @contextmenu.prevent="openContextMenu($event, {data: data, node: node})">
            <ZemInput
              v-if="data.new"
              v-model="data.name"
              class="mt-0"
              placeholder="Название"
              type="text"
              @update-press-enter="action(addChild ? 'create_children' : 'create', node, data)"
            />
            <ZemInput
              v-else
              v-model="data.name"
              class="mt-0"
              placeholder="Название"
              type="text"
              @update-press-enter="action('save_rename', node, data)"
            />
            <zem-preloader v-if="loading" :color="'dark'" />
            <img
              v-else-if="data.new"
              :src="require('@/assets/icons/check_primary.svg')"
              alt=""
              @click.prevent="action(addChild ? 'create_children' : 'create', node, data)"
            />
            <img
              v-else
              :src="require('@/assets/icons/check_primary.svg')"
              alt=""
              @click.prevent="action('save_rename', node, data)"
            />
            <img
              v-if="!loading"
              :src="require('@/assets/icons/close.svg')"
              alt=""
              @click.prevent="action('cancel', node, data)"
            />
          </span>
        </span>
      </el-tree>

      <preloader-wrapper :show="isLoadingTree" />

      <vue-context :ref="`menu`" class="p-0" close-on-click close-on-scroll>
        <template slot-scope="data">
          <ul>
            <li
              v-for="(option, i) of listDropdown"
              :key="i"
              class="menu__dropdown-item"
              @click="selectedDropdown($event, option.value, data.data)"
            >
              <p>
                {{ option.title }}
              </p>
            </li>
          </ul>
        </template>
      </vue-context>
    </div>

    <ZemModal
      :loading-success="loadingDelete"
      :show="showDeleteModal"
      :title-cancel="$t('No')"
      :title-success="$t('Yes')"
      title="Уверены, что хотите удалить?"
      @on-change="showDeleteModal = !showDeleteModal"
      @on-success="deleteElTree"
      @on-cancel="showDeleteModal = false"
    />
  </div>
</template>

<script>
import VueContext from 'vue-context'
import ZemInput from '@/components/ui/ZemInput.vue'
import {errorHandler, logger} from '@/assets/scripts/scripts'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import {directive as onClickaway} from 'vue-clickaway'
import ZemLink from '@/components/ui/ZemLink.vue'
import {eventBus} from '@/main'
import ZemModal from '@/components/ui/ZemModal.vue'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'
import ZemTable from '@/components/ui/ZemTable.vue'
import OrdersService from '@/services/orders.service'

let id = 1000

export default {
  components: {
    ZemTable,
    PreloaderWrapper,
    ZemModal,
    ZemLink,
    ZemPreloader,
    ZemInput,
    VueContext,
  },
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    checkboxes: {
      type: Boolean,
      default: false,
    },
    isLoadingTree: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editSection: '',
      filterText: '',
      addChild: false,
      loading: false,
      showDeleteModal: false,
      loadingDelete: false,
      deleteData: null,
      newElement: '',
      level: 1,
    }
  },

  computed: {
    listDropdown() {
      let list = []

      if (this.level === 1) {
        list = [
          {
            title: 'Добавить воронку',
            value: 1,
          },
          {
            title: 'Добавить этап',
            value: 2,
          },
          {
            title: this.$t('Rename'),
            value: 3,
          },
          {
            title: this.$t('Settings'),
            value: 5,
          },
          {
            title: this.$t('Delete'),
            value: 4,
          },
        ]
      } else {
        list = [
          {
            title: 'Добавить этап',
            value: 1,
          },
          {
            title: this.$t('Rename'),
            value: 3,
          },
          {
            title: this.$t('Settings'),
            value: 5,
          },
          {
            title: this.$t('Delete'),
            value: 4,
          },
        ]
      }

      return list
    },
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },

  methods: {
    logger,
    changeTree() {
      // ProductsService.rebuildTree({
      //   tree: getAllIdsTree(this.list),
      // })
    },
    async openContextMenu(e, {data, node}) {
      this.level = await node.level
      this.$refs.menu.open(e, {data: data, node: node})
    },
    async action(action, node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      const newChild = {
        label: 'Новая воронка',
        edit: true,
        new: true,
        children: [],
      }

      const clearGroup = (r, text) => {
        children[index].new = false
        children[index].edit = false
        children[index] = {
          ...r.data.data,
          children: r.data.data.children === null ? [] : r.data.data.children,
        }
        node.data = {
          ...r.data.data,
          edit: false,
          new: false,
          children: [],
        }

        this.loading = false
        this.$toast.success(this.$t(text))
      }

      const onFunnel = (id, create) => {
        this.loading = true
        if (create) {
          OrdersService.createFunnel({
            name: data.name,
          })
            .then(r => {
              clearGroup(r, 'The funnel has been created')
              this.loading = false
              OrdersService.getTree()
            })
            .catch(e => {
              errorHandler(e)
              this.loading = false
            })
        } else {
          OrdersService.updateFunnel(id, {
            name: data.name,
          })
            .then(r => {
              clearGroup(r, 'The funnel has been updated')
              this.loading = false
            })
            .catch(e => {
              errorHandler(e)
              this.loading = false
            })
        }
      }

      const onStage = (id, create) => {
        this.loading = true
        if (create) {
          OrdersService.createStage({
            name: data.name,
            pipeline_id: id,
          })
            .then(r => {
              clearGroup(r, 'The stage has been created')
              this.loading = false
              OrdersService.getTree()
            })
            .catch(e => {
              errorHandler(e)
              this.loading = false
            })
        } else {
          OrdersService.updateStage(id, {
            name: data.name,
          })
            .then(r => {
              clearGroup(r, 'The stage has been updated')
              this.loading = false
            })
            .catch(e => {
              errorHandler(e)
              this.loading = false
            })
        }
      }

      switch (action) {
        case 'create':
          if (node.level === 1) {
            onFunnel(data.id, true)
          } else {
            onStage(node.parent.data.id, true)
          }
          break
        case 'create_children':
          onStage(node.parent.data.id, true)
          this.addChild = false
          break
        case 'rename':
          this.$refs.tree.children.forEach(el => {
            el.edit = false
          })
          this.newElement = data.title
          node.data.edit = true
          break
        case 'save_rename':
          if (node.level === 1) {
            onFunnel(data.id, false)
          } else {
            onStage(data.id, false)
          }
          break
        case 'remove':
          this.deleteData = {
            node: node,
            data: data,
          }
          this.showDeleteModal = true
          break
        case 'append':
          if (parent.parent === null) {
            parent.data.push(newChild)
          } else {
            parent.data.children.push(newChild)
          }
          break
        case 'append_child':
          this.addChild = true
          if (data.children === null) data.children = []
          data.children.push(newChild)
          node.expanded = true
          break
        case 'cancel':
          if (data.edit) {
            data.edit = false
            data.title = this.newElement
            this.newElement = ''
            if (data.new) {
              const index = children.findIndex(d => d.id === data.id)
              children.splice(index, 1)
            }
          } else {
            data.edit = false
            this.deleteElTree(node, data)
          }
          break
        case 'settings':
          await this.$router.push({
            name: 'orders-settings-id',
            params: {id: data.id},
            query: {type: data.object === 'Pipeline' ? 'pipeline' : 'status'},
          })
          this.$store.commit('sidebars/changeRightSidebar', true)
          this.$emit('on-change-show-settings', true)
          break
      }
    },

    deleteElTree() {
      this.loadingDelete = true
      if (this.deleteData.node.level === 1) {
        OrdersService.deleteFunnel(this.deleteData.data.id)
          .then(() => {
            const parentMain = this.deleteData.node.parent
            const children = parentMain.data.children || parentMain.data
            const index = children.findIndex(d => d.id === this.deleteData.data.id)
            children.splice(index, 1)
            this.loadingDelete = false
            this.showDeleteModal = false
          })
          .catch(e => {
            errorHandler(e)
            this.loadingDelete = false
            this.showDeleteModal = false
          })
      } else {
        OrdersService.deleteStage(this.deleteData.data.id)
          .then(() => {
            const parentMain = this.deleteData.node.parent
            const children = parentMain.data.children || parentMain.data
            const index = children.findIndex(d => d.id === this.deleteData.data.id)
            children.splice(index, 1)
            this.loadingDelete = false
            this.showDeleteModal = false
          })
          .catch(e => {
            errorHandler(e)
            this.loadingDelete = false
            this.showDeleteModal = false
          })
      }
    },

    filterNode(value, data) {
      if (!value) return true
      return data.title.indexOf(value) !== -1
    },

    selectedDropdown(e, value, data) {
      if (value === 1) this.action('append', data.node, data.data)
      if (value === 2) this.action('append_child', data.node, data.data)
      if (value === 3) this.action('rename', data.node, data.data)
      if (value === 4) this.action('remove', data.node, data.data)
      if (value === 5) this.action('settings', data.node, data.data)
    },

    handleNodeClick(data, node) {
      if (this.$route.name !== 'orders') this.$router.push('/orders')
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$store.commit('orders/changePage', 1)
      this.$store.commit('orders/itemsForDelete', [])
      if (this.$store.state.other.windowWidth < 1024) {
        this.$store.commit('sidebars/changeLeftSidebar', false)
      }

      this.$store.commit(
        'orders/selectedSection',
        data.object === 'Pipeline'
          ? {
              title: `Воронка: ${data.name}`,
              type: 'funnel',
              id: data.id,
            }
          : {
              title: `Воронка: ${node.parent.data.name} | Этап: ${data.name}`,
              type: 'stage',
              id: data.id,
            }
      )

      OrdersService.getAllOrders().then(r => {
        this.$emit('on-change-orders', {count: r.data.meta.pagination.count, section: r.section})
      })

      eventBus.$emit('set-category-item', data)

      let arr = []

      arr.push(node.data.name)
      if (node.level === 2) arr.push(node.parent.data.name)

      this.$store.commit('orders/breadCrumbs', arr)
    },
  },
}
</script>

<style lang="scss">
.rename-block {
  display: flex;
  align-items: center;
  //margin: -3px 0 0 -9px;

  .zem-input {
    font-size: 14px;
    margin: 0;
    padding: 3px 8px;
    min-height: 24px;
    color: #606266;
  }

  img {
    width: 16px;
    margin-left: 5px;
    cursor: pointer;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 8px;
}
</style>
