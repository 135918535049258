<template>
  <div class="tw-w-full tw-h-[calc(100%-45px)] md:tw-h-full">
    <div v-if="element" class="sidebar-right-container position-relative tw-overflow-y-auto tw-h-full tw-w-full">
      <zem-card>
        <div>
          <zem-card-title>{{ element.title }}</zem-card-title>
          <div v-if="!closeAcc([3])" class="block-id">
            <span class="block-id__label second"></span>
            ID {{ element['id'] }}
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-gap-x-3 cursor-pointer">
          <img :src="require('@/assets/icons/close.svg')" alt="" class="tw-w-5" @click="closeRightSidebar" />
        </div>
      </zem-card>

      <div class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2 tw-mt-3">
        <div class="tw-text-[#9ba6b2] tw-text-xs">Название</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
          {{ element['title'] ? element['title'] : '' }}
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Статус</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
          {{ element['status'] ? element['status'].title : '' }}
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Ответственный</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
          {{ element['responsibleBy'] ? element['responsibleBy'].title : '' }}
        </div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Бюджет</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">{{ element['budgetEdit'] }}</div>

        <div class="tw-text-[#9ba6b2] tw-text-xs">Объект</div>
        <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E]">
          {{ element['object'] ? element['object'].title : '' }}
        </div>

        <template v-for="(schema, index) in element.params_schema">
          <template v-if="schema.key === 'disk_url'">
            <div class="tw-text-[#9ba6b2] tw-text-xs">{{ schema.title }}</div>
            <RouterLink
              v-if="schema.value"
              :to="schema.value"
              class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E] zem-link text-primary"
              target="_blank"
            >
              {{ schema.value }}
            </RouterLink>
          </template>
        </template>
      </div>

      <zem-collapse is-opened-default title="Заказчики">
        <div v-if="customers.length" class="zem-collapse-table rows">
          <div v-for="(item, index) in customers" :key="index" class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2">
            <p class="tw-text-[#9ba6b2] tw-text-xs">{{ item['human_position'].title }}</p>
            <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E] tw-flex tw-justify-between">
              {{ item['human'].title }}
              <zem-dropdown-menu>
                <template v-slot:options>
                  <RouterLink
                    target="_blank"
                    :to="`/counterparties/peoples/${item['human'].value}`"
                    class="tw-block tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E] tw-transition tw-text-xs"
                  >
                    Перейти в карточку
                  </RouterLink>
                </template>
              </zem-dropdown-menu>
            </div>
          </div>
        </div>
        <p v-else class="tw-text-[#9ba6b2] tw-text-xs">Нет значений</p>
      </zem-collapse>

      <zem-collapse is-opened-default title="Исполнители">
        <div v-if="performers.length" class="zem-collapse-table rows">
          <div v-for="(item, index) in performers" :key="index" class="tw-grid tw-grid-cols-3 tw-gap-x-1.5 tw-gap-y-2">
            <p class="tw-text-[#9ba6b2] tw-text-xs">{{ item['human_position'].title }}</p>
            <div class="tw-col-span-2 tw-text-xs tw-text-[#3E3E3E] tw-flex tw-justify-between">
              {{ item['human'].title }}
              <zem-dropdown-menu>
                <template v-slot:options>
                  <RouterLink
                    target="_blank"
                    :to="`/counterparties/peoples/${item['human'].value}`"
                    class="tw-block tw-text-nowrap tw-px-3 tw-py-1 hover:tw-bg-[#0DB2B2]/20 tw-text-[#3E3E3E] tw-transition tw-text-xs"
                  >
                    Перейти в карточку
                  </RouterLink>
                </template>
              </zem-dropdown-menu>
            </div>
          </div>
        </div>
        <p v-else class="tw-text-[#9ba6b2] tw-text-xs">Нет значений</p>
      </zem-collapse>

      <zem-collapse v-if="object" is-opened-default title="Объект">
        <OrderObjectBlock :element="object" />
      </zem-collapse>

      <zem-collapse v-if="element.events.data.length" is-opened-default title="Мероприятия">
        <OrderEventsBlock
          :elements="element.events ? element.events.data : []"
          :is-creating="newElement"
          :is-editing="editElement"
        />
      </zem-collapse>

      <zem-collapse is-opened-default title="Сметы">
        <OrderEstimatesBlock />
      </zem-collapse>
    </div>

    <preloader-wrapper :show="$store.state.other.isLoadingItem" />
  </div>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard'
import {closeAcc, logger} from '@/assets/scripts/scripts'
import {options} from '@/mixins/options'
import SidebarRightHeader from '@/components/SidebarRightHeader.vue'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import {PlusIcon} from 'vue-feather-icons'
import OrderEventsBlock from '@/components/Orders/OrderEventsBlock.vue'
import OrderEstimatesBlock from '@/components/Orders/OrderEstimatesBlock.vue'
import OrdersService from '@/services/orders.service'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import ObjectsService from '@/services/objects.service'
import OrderPersonsBlock from '@/components/Orders/OrderPersonsBlock.vue'
import OrderObjectBlock from '@/components/Orders/OrderObjectBlock.vue'
import dayjs from 'dayjs'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import OtherService from '@/services/other.service'
import EventsService from '@/services/events.service'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import IEllipsis from '@/components/icons/iEllipsis.vue'
import ZemDropdownMenu from '@/components/ui/ZemDropdownMenu.vue'

export default {
  emits: ['on-update', 'on-edit', 'on-close', 'on-show-events-list'],
  mixins: [options],
  components: {
    ZemDropdownMenu,
    IEllipsis,
    ZemPreloader,
    ZemCardTitle,
    ZemButton,
    ZemLink,
    OrderObjectBlock,
    ZemInput,
    ZemDropdownList,
    OrderEstimatesBlock,
    OrderEventsBlock,
    ZemCollapse,
    OrderPersonsBlock,
    PreloaderWrapper,
    SidebarRightHeader,
    ZemCard,
    PlusIcon,
  },
  data() {
    return {
      editElement: true,
      newElement: false,
      elementData: null,
      title: '',
      id: '',
      element: null,
      object: null,
      events: [
        {
          id: 0,
          title: 'Дата создания',
          data: '13.09.2023',
        },
        {
          id: 1,
          title: 'Дата завершения',
          data: '25.09.2024',
        },
      ],
      currentElementId: null,
      createItemLoading: false,
      customers: [],
      performers: [],
      persons: [],
      customersList: [],
      performersList: [],
      customerID: null,
      performerID: null,
      defaultPerson: {
        human_position: {
          title: null,
          value: null,
        },
        human: {
          title: null,
          value: null,
        },
        isLoading: false,
      },
      schemaData: [],
      orderStatuses: [],
      communityStatuses: [],
      humanPositions: [],
      isLoadingPeoples: false,
    }
  },
  computed: {
    selectedItems() {
      return this.$store.state.orders.selectedItemsForDelete
    },
    users() {
      return this.$store.state.users.users
    },
  },
  mounted() {
    OrdersService.getOrderStatuses().then(r => {
      this.orderStatuses = r.data.data.map(status => ({
        title: `${status.pipeline ? status.pipeline.data.name + ' - ' : ''}${status.name}`,
        value: status.id,
      }))
    })
    OtherService.getCommunityStatuses().then(r => {
      this.communityStatuses = r.data.data.map(el => ({
        title: el.title,
        value: el.id,
      }))
      this.customerID = r.data.data.find(el => el.title === 'Заказчик').id
      this.performerID = r.data.data.find(el => el.title === 'Исполнитель').id
    })
    OtherService.getHumanPositions().then(r => {
      this.humanPositions = r.data.data.map(el => ({
        title: el.title,
        value: el.id,
      }))
    })
  },
  methods: {
    closeAcc,
    logger,
    getPeoples(i, search, type) {
      this.customersList = []
      this.performersList = []
      if (type) this.customers[i].isLoading = true
      else this.performers[i].isLoading = true
      EventsService.getPeoplesList(search).then(r => {
        if (type) {
          this.customersList = r
            .filter(el => !el.user_id)
            .map(data => ({
              title: data.fio,
              value: data.id,
            }))
          this.customers[i].isLoading = false
        } else {
          this.performersList = r
            .filter(el => el.user_id)
            .map(data => ({
              title: data.fio,
              value: data.id,
            }))
          this.performers[i].isLoading = false
        }
      })
    },
    getOrder() {
      this.$store.commit('other/onChangeLoadingItem', true)
      const {id} = this.$route.params
      const defaultValue = {
        title: null,
        value: null,
      }
      OrdersService.getOrder(id)
        .then(r => {
          const {responsible_by, status, object, object_id, budget, pipeline, events} = r.data.data

          this.element = {
            ...r.data.data,
            responsibleBy: responsible_by
              ? {
                  title: responsible_by.data.name,
                  value: responsible_by.data.id,
                }
              : defaultValue,
            status: status
              ? {
                  title: `${pipeline.data.name} - ${status.data.name}`,
                  value: status.data.id,
                }
              : defaultValue,
            object: object
              ? {
                  title: object.data.name,
                  value: object.data.id,
                }
              : defaultValue,
            objectValue: object,
            budgetEdit: budget.currency.value,
          }

          if (object_id) {
            ObjectsService.getObject(object_id).then(r => {
              this.object = r.data.data
            })
          } else {
            this.object = null
          }

          const participants = r.data.data.participants.data
          let titlesParticipants = []
          let resultParticipants = []
          let counter = 0

          for (let participant of participants) {
            if (!titlesParticipants.includes(participant.community_status.data.title)) {
              titlesParticipants.push(participant.community_status.data.title)
              resultParticipants.push({
                title: participant.community_status.data.title,
                id: participant.human.data.id,
                value: [
                  {
                    human_position: participant.human_position.data.title,
                    human: participant.human.data.fio,
                  },
                ],
              })
            } else {
              const i = resultParticipants.findIndex(el => participant.community_status.data.title === el.title)
              resultParticipants[i].value.push({
                human_position: participant.human_position.data.title,
                human: participant.human.data.fio,
              })
            }
            counter++
          }

          events.data.forEach(event => {
            if (!event.deadlines.data.length) return
            const {deadlines} = event
            const datesStart = deadlines.data.map(e => e.start_at)
            const datesEnd = deadlines.data.map(e => e.finish_at)
            const earliestDate = datesStart
              .map(e => e.start_at)
              .reduce((earliest, current) => {
                return current < earliest ? current : earliest
              }, datesStart[0])
            const endDate = deadlines.data
              .map(e => e.finish_at)
              .reduce((latest, current) => {
                return current > latest ? current : latest
              }, datesEnd[0])
            event.start_at = earliestDate ? dayjs(earliestDate).unix() : null
            event.finish_at = endDate ? dayjs(endDate).unix() : null
          })

          this.persons = resultParticipants
          this.$store.commit('other/onChangeLoadingItem', false)

          const handlerHuman = data => {
            return {
              community_status: {
                title: data.community_status.data.title,
                value: data.community_status.data.id,
              },
              human_position: {
                title: data.human_position.data.title,
                value: data.human_position.data.id,
              },
              human: {
                title: data.human.data.fio,
                value: data.human.data.id,
              },
              isLoading: false,
            }
          }

          this.customers = participants
            .filter(el => el.community_status.data.title === 'Заказчик')
            .map(el => handlerHuman(el))

          this.performers = participants
            .filter(el => el.community_status.data.title === 'Исполнитель')
            .map(el => handlerHuman(el))
        })
        .catch(e => {
          if (e.response.status === 422 && this.$route.name !== 'orders') this.$router.push({name: 'orders'})
          this.closeRightSidebar()
        })
    },
    closeRightSidebar() {
      this.editElement = false
      this.newElement = false
      this.$router.push({name: 'orders'})
      this.$emit('on-close')
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.sidebar-right-container {
  > .zem-card:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
      display: flex;

      //img:not(:first-child) {
      //  margin-left: 8px;
      //}

      .block-id__delete {
        width: 17px;
        cursor: pointer;
      }
    }
  }

  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  -moz-flex-direction: column;
  overflow-y: auto;

  .zem-dropdown {
    margin-top: 0;
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1024px) {
  .sidebar-right-container {
    width: 100%;
    height: calc(100% - 45px);
    overflow: auto;
  }
}
</style>
