<template>
  <zem-card class="tw-flex-col">
    <section class="tw-w-full tw-flex tw-justify-between tw-items-start">
      <div>
        <template v-if="editing || creating">
          <zem-card-title v-if="!creating">{{ title }}</zem-card-title>
          <zem-card-title v-else class="tw-mb-0">Создание заказа</zem-card-title>
          <div v-if="editing && !creating" class="tw-flex tw-items-center tw-text-xs tw-text-gray-700">
            <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0B9999] tw-rounded-full tw-mr-1"></span>
            ID {{ id }}
          </div>
        </template>
        <template v-else>
          <zem-card-title>{{ title }}</zem-card-title>
          <div class="tw-flex tw-items-center tw-text-xs tw-text-gray-700">
            <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0B9999] tw-rounded-full tw-mr-1"></span>
            ID {{ id }}
          </div>
        </template>
        <div v-if="!closeAcc([3]) && !creating" class="tw-flex tw-items-center tw-text-xs tw-text-gray-700">
          <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0EC6C6] tw-rounded-full tw-mr-1"></span>
          ID {{ hashId }}
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-gap-2">
        <template v-if="(!elementData || creating || editing) && !loading">
          <template v-if="!creating && !editing">
            <ExternalLinkIcon
              v-tippy="'Перейти в CRM'"
              class="text-primary tw-cursor-pointer tw-outline-none"
              size="16"
            />
            <ListIcon
              v-tippy="'История'"
              class="text-primary tw-cursor-pointer tw-outline-none"
              size="20"
              @click="$emit('on-show-events-list')"
            />
            <XIcon class="text-primary tw-cursor-pointer" size="18" @click="closeRightSidebar" />
          </template>
          <template v-else-if="closeAcc([5, 7])">
            <zem-link
              class="mr-2 mobile-d-none"
              @click="
                () => {
                  $emit('close')
                }
              "
            >
              Отменить
            </zem-link>
            <zem-button
              class="mobile-d-none"
              @click="
                () => {
                  $emit(creating ? 'create' : 'update')
                }
              "
            >
              Сохранить
            </zem-button>
          </template>
        </template>

        <zem-button v-if="loading" disabled>
          <zem-preloader color="light" />
        </zem-button>
      </div>
    </section>
    <slot name="bottom"></slot>
  </zem-card>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard.vue'
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import {closeAcc} from '@/assets/scripts/scripts'
import {Edit3Icon, ExternalLinkIcon, ListIcon, XIcon} from 'vue-feather-icons'
import zapIcon from 'vue-feather-icons/icons/ZapIcon'

export default {
  components: {
    ZemButton,
    ZemCard,
    ZemCardTitle,
    ZemPreloader,
    ZemLink,
    ListIcon,
    ExternalLinkIcon,
    Edit3Icon,
    XIcon,
  },
  emits: ['on-show-events-list', 'close', 'create', 'update', 'edit-element'],
  props: ['title', 'id', 'hashId', 'editing', 'creating', 'elementData', 'loading', 'element', 'isShowEditButton'],
  data() {
    return {}
  },
  computed: {
    zapIcon() {
      return zapIcon
    },
    typeMode() {
      let index = this.element.schema.findIndex(el => el.identifier === 'title')
      return this.element.schema.length > 0 ? this.element.schema[index]['fields'][0].data.mode : 'manual'
    },
  },
  methods: {
    closeAcc,
    closeRightSidebar() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  > div:last-child {
    display: flex;

    img:not(:first-child) {
      margin-left: 8px;
    }

    .block-id__delete {
      width: 17px;
      color: $color-accent;
    }
  }
}

.zem-dropdown {
  margin-top: 0;
}

@media screen and (max-width: 1024px) {
  .sidebar-right-header {
    > div:first-child {
      width: 100%;
    }

    .block-id__edit {
      max-height: 17px;
      min-width: 12px;
    }

    .block-id__delete {
      max-height: 17px;
      min-width: 17px;
    }
  }
}
</style>
