<template>
  <div>
    <zem-collapse
      :arrow="false"
      class="mt-0"
      not-border
    >
      <template v-slot:title>
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-items-center">
            <span>v1</span>
            <span class='tw-w-1.5 tw-h-1.5 tw-rounded-full tw-bg-[#FF9700] tw-mx-2'></span>
            <span>Согласовано</span>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <BookOpenIcon class="text-primary tw-cursor-pointer" size="18"/>
            <CopyIcon class="text-primary tw-cursor-pointer" size="18"/>
            <ExternalLinkIcon class="text-primary tw-cursor-pointer" size="18"/>
          </div>
        </div>
      </template>
      <div class="zem-collapse-table rows">
        <div class="zem-collapse-table__row" style="grid-template-columns: 1fr 2fr; gap: 5px 10px;">
          <div class="zem-collapse-table__column title mt-1">Итого, руб.</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            290 000
          </div>
          <div class="zem-collapse-table__column title mt-1">Рентабельность, %</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            48
          </div>
          <div class="zem-collapse-table__column title mt-1">Срок, дней</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            14
          </div>
        </div>
      </div>
    </zem-collapse>
    <zem-collapse
      :arrow="false"
      class="mt-0"
      not-border
    >
      <template v-slot:title>
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-items-center">
            <span>v2</span>
            <span class='tw-w-1.5 tw-h-1.5 tw-rounded-full tw-bg-[#52C41A] tw-mx-2'></span>
            <span>Финал</span>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <BookOpenIcon class="text-primary tw-cursor-pointer" size="18"/>
            <CopyIcon class="text-primary tw-cursor-pointer" size="18"/>
            <ExternalLinkIcon class="text-primary tw-cursor-pointer" size="18"/>
          </div>
        </div>
      </template>
      <div class="zem-collapse-table rows">
        <div class="zem-collapse-table__row" style="grid-template-columns: 1fr 2fr; gap: 5px 10px;">
          <div class="zem-collapse-table__column title mt-1">Итого, руб.</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            290 000
          </div>
          <div class="zem-collapse-table__column title mt-1">Рентабельность, %</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            48
          </div>
          <div class="zem-collapse-table__column title mt-1">Срок, дней</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            14
          </div>
        </div>
      </div>
    </zem-collapse>
    <zem-collapse
      :arrow="false"
      class="mt-0"
      not-border
    >
      <template v-slot:title>
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-items-center">
            <span>v3</span>
            <span class='tw-w-1.5 tw-h-1.5 tw-rounded-full tw-bg-[#F00] tw-mx-2'></span>
            <span>Отклонена</span>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <BookOpenIcon class="text-primary tw-cursor-pointer" size="18"/>
            <CopyIcon class="text-primary tw-cursor-pointer" size="18"/>
            <ExternalLinkIcon class="text-primary tw-cursor-pointer" size="18"/>
          </div>
        </div>
      </template>
      <div class="zem-collapse-table rows">
        <div class="zem-collapse-table__row" style="grid-template-columns: 1fr 2fr; gap: 5px 10px;">
          <div class="zem-collapse-table__column title mt-1">Итого, руб.</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            290 000
          </div>
          <div class="zem-collapse-table__column title mt-1">Рентабельность, %</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            48
          </div>
          <div class="zem-collapse-table__column title mt-1">Срок, дней</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            14
          </div>
        </div>
      </div>
    </zem-collapse>
    <zem-collapse
      :arrow="false"
      class="mt-0"
      not-border
    >
      <template v-slot:title>
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-items-center">
            <span>v4</span>
            <span class="tw-w-1.5 tw-h-1.5 tw-rounded-full tw-bg-[#B2B4B1] tw-mx-2"></span>
            <span>Черновик</span>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <BookOpenIcon class="text-primary tw-cursor-pointer" size="18"/>
            <CopyIcon class="text-primary tw-cursor-pointer" size="18"/>
            <ExternalLinkIcon class="text-primary tw-cursor-pointer" size="18"/>
          </div>
        </div>
      </template>
      <div class="zem-collapse-table rows">
        <div class="zem-collapse-table__row" style="grid-template-columns: 1fr 2fr; gap: 5px 10px;">
          <div class="zem-collapse-table__column title mt-1">Итого, руб.</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            290 000
          </div>
          <div class="zem-collapse-table__column title mt-1">Рентабельность, %</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            48
          </div>
          <div class="zem-collapse-table__column title mt-1">Срок, дней</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            14
          </div>
        </div>
      </div>
    </zem-collapse>
    <zem-collapse
      :arrow="false"
      class="mt-0"
      not-border
    >
      <template v-slot:title>
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-items-center">
            <span>v5</span>
            <span class="tw-w-1.5 tw-h-1.5 tw-rounded-full tw-bg-[#B2B4B1] tw-mx-2"></span>
            <span>Черновик</span>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <BookOpenIcon class="text-primary tw-cursor-pointer" size="18"/>
            <CopyIcon class="text-primary tw-cursor-pointer" size="18"/>
            <ExternalLinkIcon class="text-primary tw-cursor-pointer" size="18"/>
          </div>
        </div>
      </template>
      <div class="zem-collapse-table rows">
        <div class="zem-collapse-table__row" style="grid-template-columns: 1fr 2fr; gap: 5px 10px;">
          <div class="zem-collapse-table__column title mt-1">Итого, руб.</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            290 000
          </div>
          <div class="zem-collapse-table__column title mt-1">Рентабельность, %</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            48
          </div>
          <div class="zem-collapse-table__column title mt-1">Срок, дней</div>
          <div class="zem-collapse-table__column text mt-1" style="white-space: unset;">
            14
          </div>
        </div>
      </div>
    </zem-collapse>
  </div>
</template>

<script>
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import {logger} from '@/assets/scripts/scripts'
import ZemCollapse from "@/components/ui/ZemCollapse.vue";
import {BookOpenIcon, CopyIcon, ExternalLinkIcon} from "vue-feather-icons";

export default {
  components: {
    ZemCollapse,
    ZemRadioButton,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ExternalLinkIcon,
    CopyIcon,
    BookOpenIcon,
  },
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isCreating: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    idx: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {}
  },
  methods: {
    logger,
    values(item) {
      if (item.type === 'list') {
        return item.data.title
      } else if (item.type === 'groups') {
        let valuesToString = ''
        item.data.forEach((el, index, arr) => {
          valuesToString += el.title
          if (arr.length !== index + 1) {
            valuesToString += ', '
          }
        })
        return valuesToString
      } else if (item['input_name'] === 'bundle') {
        return item.data.title
      } else {
        return item.data
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.zem-dropdown {
  margin-top: 0;
}
</style>
